/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
}

.app-wrapper {
  // padding: 30px;
  // padding-top: 30px;
  // padding-bottom: 30px;
  // padding-right: 100px;
  // padding-left: 100px;
  width: 100%;

  @media screen and (max-width: 575px) {
    padding: 20px;
  }
}